

.ftr-grid h3 {
    font-size: 1.8em;
    color: #555;
    font-family: 'Dosis-Medium';
    margin: 0 0 1em;
}

ul.nav-bottom li:nth-child(1) {
    margin: 0;
}
ul.nav-bottom li {
    list-style: none;
    padding: 2px 0;
}

.about-us h3 {
    color: #555;
    font-size: 1.8em;
    font-family: 'Dosis-Medium';
    margin-bottom: 1em;
}

.about-us p {
    line-height: 1.7em;
    color: #7F7F7F;
    font-size: 0.9em;
}

ul.nav-bottom li a {
    text-decoration: none;
    color: #7F7F7F;
    font-size: 0.9em;
    display: inline-block;
}

ul.nav-bottom li a:active{
    color: #ed645c;
}
ul.nav-bottom li a:hover{
    color: #ed645c;
}

.copywrite {
    padding: 1em 0;
    background: #7D7D7D;
}
.copy {
    float: left;
}

.copy p {
    color: #fff;
    font-size: 0.95em;
    padding-top: 10px;
}

footer{
    background: #fff !important;
    padding-top: 50px;
}