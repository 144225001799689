body{
    overflow-x:hidden;
}
.product{
    margin-top: 50px;
    margin-bottom: 100px;
}

.card-container{
    margin-bottom: 50px;
}

.card-container div{
    margin-bottom: 20px !important;
    /*padding: 30px !important;*/
}


.product-row{
    padding-left: 20px !important;
    padding-right: 20px !important;
}

@media (max-width: 669px) {
    .product-row{
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}



header .item {
    height: 100vh;
    position: relative;
}
header .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
header .item .cover {
    padding: 75px 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
}
header .item .cover .header-content {
    position: relative;
    padding: 56px;
    overflow: hidden;
}
header .item .cover .header-content .line {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    border: 9px solid #fff;
    -webkit-clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
    clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
}
header .item .cover .header-content h2 {
    font-weight: 300;
    font-size: 35px;
    color: #fff;
}
header .item .cover .header-content h1 {
    font-size: 56px;
    font-weight: 600;
    margin: 5px 0 20px;
    word-spacing: 3px;
    color: #fff;
}
header .item .cover .header-content h4 {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
    color: #fff;
}
header .owl-item.active h1 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: 0.3s;
}
header .owl-item.active h2 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInDown;
    animation-delay: 0.3s;
}
header .owl-item.active h4 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInUp;
    animation-delay: 0.3s;
}
header .owl-item.active .line {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
    animation-delay: 0.3s;
}
header .owl-nav .owl-prev {
    position: absolute;
    left: 15px;
    top: 43%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background: rgba(0, 0, 0, 0.5) !important;
    width: 40px;
    cursor: pointer;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 0;
}
header .owl-nav .owl-prev span {
    font-size: 1.6875rem;
    color: #fff;
}
header .owl-nav .owl-prev:focus {
    outline: 0;
}
header .owl-nav .owl-prev:hover {
    background: #000 !important;
}
header .owl-nav .owl-next {
    position: absolute;
    right: 15px;
    top: 43%;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background: rgba(0, 0, 0, 0.5) !important;
    width: 40px;
    cursor: pointer;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 1000;
    border-radius: 0;
}
header .owl-nav .owl-next span {
    font-size: 1.6875rem;
    color: #fff;
}
header .owl-nav .owl-next:focus {
    outline: 0;
}
header .owl-nav .owl-next:hover {
    background: #000 !important;
}
header:hover .owl-prev {
    left: 0px;
    opacity: 1;
}
header:hover .owl-next {
    right: 0px;
    opacity: 1;
}

.w3-black, .w3-hover-black:hover {
    color: #fff !important;
    font-weight: bold;
    font-size: 35px !important;
    background-color: rgba(0,0,0,0.1)!important;
}

.home-image{
    width:100%;
    height:600px !important;
}

@media (max-width: 669px) {
    .home-image{
        height:400px !important;
    }
    .w3-black, .w3-hover-black:hover {
        font-size: 20px !important;
        background-color: rgba(0,0,0,0.5)!important;
        width:80%;
    }

}

.product .card-img {
    height: 500px !important;
}

.product-head-row{
    margin-top: 3%;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}