.about{
    margin-top: 100px !important;
    margin-bottom: 100px !important;
    cursor: pointer;
    /*background-color: #6B062D;*/
    /*color: #6B062D;*/
    color: black;
    padding-top: 20px;
    padding-bottom: 30px;
}

@media(max-width: 512px) {
    .about{
        margin-top: 5px !important;
        margin-bottom: 10px !important;
    }
}
.about h1 {
    padding: 10px 0;
    margin-bottom: 20px;
}
.about h2 {
    opacity: .8;
}
.about img {
    display: block;
    width: 200px;
    height: 200px;
    line-height: 200px;
    margin:auto;
    border-radius:50%;
    font-size: 40px;
    color: #FFFFFF;
    opacity: 0.9;
    background-color: #4C0822;
    border: 2px solid #4C0822;

    webkit-transition:all .5s ease;
    moz-transition:all .5s ease;
    os-transition:all .5s ease;
    transition:all .5s ease;

}
.about-item:hover img{
    opacity: 1;
    border: 2px solid #CC0039;
    font-size: 42px;
    -webkit-transform: scale(1.1,1.1) rotate(360deg) ;
    -moz-transform: scale(1.1,1.1) rotate(360deg) ;
    -o-transform: scale(1.1,1.1) rotate(360deg) ;
    transform: scale(1.1,1.1) rotate(360deg) ;
}
.about-item:hover h2{
    opacity: 1;
    -webkit-transform: scale(1.1,1.1)  ;
    -moz-transform: scale(1.1,1.1)  ;
    -o-transform: scale(1.1,1.1)  ;
    transform: scale(1.1,1.1) ;
}
.about .lead{
    color: #CC0039;
    font-size: 14px;
    font-weight: bold;
}
