/*.card {*/
/*    box-shadow: 8px 4px 8px 4px rgba(0,0,0,0.2);*/
/*    transition: 0.3s;*/
/*    width: 300px;*/
/*    height: 200px;*/
/*    border-radius: 5px;*/
/*    margin-top: 20px;*/
/*    margin-bottom: 50px;*/

/*}*/



/*.card:hover {*/
/*    box-shadow: 16px 8px 16px 8px rgba(0,0,0,0.2);*/
/*}*/

/*img {*/
/*    border-radius: 5px 5px 0 0;*/
/*}*/

/*.container {*/
/*    padding: 2px 16px;*/
/*}*/

.arrival-info{
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.arrival-info p {
    color: #000;
    font-size: 1em;
    font-weight: 600;
    margin: 10px 0 5px;
}

span.pric1 {
    color: #ADADAD;
    font-size: 0.95em;
}

span.disc {
    color: rgba(7, 152, 40, 0.69);
    font-size: 0.85em;
}

.card-img{
    height:400px;
    width:100%;
}
.column{
    padding-left: 50px !important;
    padding-right: 50px !important;
}

/*.item{*/
/*    padding-left:5px;*/
/*    padding-right:5px;*/
/*    text-align: left;*/
/*}*/
.item-card{
    transition:0.5s;
    cursor:pointer;
}
.item-card-title{
    font-size:20px;
    transition:1s;
    cursor:pointer;
    font-weight: bold;
}
.item-card-title i{
    font-size:15px;
    transition:1s;
    cursor:pointer;
    color:#ffa710
}
.card-title i:hover{
    transform: scale(1.25) rotate(100deg);
    color:#18d4ca;

}
.card:hover{
    transform: scale(1.05);
    box-shadow: 10px 10px 15px rgba(0,0,0,0.3);
    background: white;
}
/*.card-text{*/
/*    height:80px;*/
/*}*/

.card::before, .card::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 0, 1);
    transition: transform .3s ease-out 0s;
    /* background: rgba(255, 255, 255, 0.1); */
    content: '';
    pointer-events: none;
}
.card::before {
    transform-origin: left top;
}
.card::after {
    transform-origin: right bottom;
}
.card:hover::before, .card:hover::after, .card:focus::before, .card:focus::after {
    transform: scale3d(1, 1, 1);
}