

body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}


.main-container{
    margin-top: 100px;
    margin-bottom: 100px;
}
input[type=text], textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=email]{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=number]{
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

input[type=button] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

input[type=button]:hover {
    background-color: #45a049;
}

.container-form {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}

.contact-us-text{
    font-weight: bold;

}

.span_1_of_3 h4 {
    font-size: 1.3em;
    padding: 0.5em 0;
    color: #00a0dc;
}

.span_1_of_3 {
   padding-left: 30px;
}
.company_address {
    padding-top: 26px;
}

.company_address p {
    font-size: 1em;
    color: rgba(85, 81, 81, 0.84);
    line-height: 1.8em;
}

a {
    color: #337ab7;
    text-decoration: none;
    -webkit-transition: 0.5s all;
}


@media (max-width: 669px) {
    .contact{
        display: flex !important;
        flex-direction: column-reverse;
    }

}