.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.company{

  background: #ffffff;
  font-size: 1.3em;
  padding: 0.2rem 1.3em;
  color: black !important;
  font-weight: bold;
  /*width: 200px;*/
  justify-content: center;
  text-align: center;
  height: 60px !important;

}
.nav-opt{
  float: right;
  padding-right: 10% !important;
}

@media (max-width: 669px) {
  .nav-opt{
    float: none;
    padding-right: 0 !important;
  }
}
#navbar a:hover {
  background-color: #ddd;
  color: black;
}

#navbar a.active {
  background-color: #4CAF50;
  color: white;
}

.content {
  padding: 16px;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 1111;
  width: 100%;
}

.nav-head{
  height: 60px !important;
}
.navbar-default .navbar-nav>.active>a{
  color: black !important;
  height: 60px !important;
  font-weight: bold;
  padding: 2rem 1.3em;
}
.navbar-default .navbar-nav>li>a{
  color: black !important;
  height: 60px !important;
  font-weight: bold;
  padding: 2rem 1.3em;

}
.sticky + .content {
  padding-top: 60px;
}

.logo{
  display: inline-block !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
